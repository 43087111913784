/* Default light theme */
* {
  margin:0;
  scroll-behavior: smooth;
}


:root {
  --background-color: #6bd3f6;
  --text-color: #000000;
  --link-color: #fff;
  --footer-background-color: #cecccc;
}

/* Dark theme */
.dark-mode {
  --background-color: #1d1c1c;  /* Dark gray */
  --text-color: #ffffff;  /* White text */
  --footer-background-color: #433e3e;  /* Slightly lighter dark gray for footer */
}

h1 {
  -webkit-text-stroke: 1px black;
}

h2 {
  font-family: 'Poppins, sans-serif';
  -webkit-text-stroke: 1px black;
}
.dark-mode h1, .dark-mode h2 {
  -webkit-text-stroke: 1px white;
}

@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
/* Container for centering */
.intro-container {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;  /* Center vertically */
  position: relative;
  margin: 0;  /* Ensure no extra margin */
  overflow-y: hidden;
}

/* Intro text */
.INTRO {
  color: #000000;
  font-weight: bold;
  font-family: "Anonymous Pro", monospace;
  letter-spacing: 7px;
  overflow-x: hidden;
  border-right: 2px solid hsl(0, 0%, 80%);
  white-space: nowrap;
  animation: typewriter 5s steps(30) 1s infinite,
    blinkTextCursor 500ms infinite;
  margin-top: 10vh; /* Ensure no extra margin */
  line-height: 1;  /* Adjust line height to reduce space within the text */
  overflow-y: hidden;
}
.dark-mode .INTRO {
  color: #ffffff;
}

@keyframes typewriter {
  0% {
    width: 0ch;
  }
  50% {
    width: 16ch;
  }
  100% {
    width: 16ch;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }
  to {
    border-right-color: transparent;
  }
}
p {
  font-family: 'Poppins, sans-serif';
}

body {
  font-family: 'Poppins, sans-serif';
  min-width: 100% !important;
  max-width: 100% !important;
  background-color: var(--background-color); /* Instead of a hardcoded value */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color); /* Use variable for text color */
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

a {
  color: var(--link-color);
  text-decoration: none;
}
footer footer {
  overflow-y: hidden;
}
.App {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  text-align: center;
  background-color: var(--background-color);
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url('/public/Sky.png');
  background-size: cover; 
}
.dark-mode .App {
  background-image: url('/public/Galaxy.png');
  background-size: cover; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  overflow-y: hidden;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color); /* Use variable for text color */
  overflow-y: hidden;
}

.App-link {
  color: var(--link-color);
}

header {
  background-color: #cecccc;
  width: 100%;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: hidden;
}
.dark-mode header {
  background-color: var(--footer-background-color);
}

ul {
  list-style-type: none; /* This removes bullet points */
  margin: 0;
  overflow-y: hidden;
}

/* External or internal CSS */
.img-small {
  align-items: left;
  margin-inline: 5vh;
  width: 50%; /* or any other size */
  height: auto;
  overflow-y: hidden;
}

nav {
  font-weight: bold;
  list-style-type: none;  /* Removes bullet points */
  margin: 0;  /* Removes margin */
  display: flex;  /* Flexbox to make the items horizontal */
  justify-content: center;  /* Centers items horizontally */
  align-items: center;  /* Align items vertically */
  box-sizing: border-box;
  width: 100%;
  margin-inline: 60px;
  margin-top: 3vh;
  margin-bottom: 3vh;
  overflow-y: hidden;
}
.dark-mode nav {
  background-color: var(--footer-background-color);
}
.logo {
  height: 40px;
  margin-right: 35px;
  filter: invert(100%); 
  overflow-y: hidden;
}
.dark-mode .logo {
  filter: invert(0%)
}

nav ul {
  list-style-type: none;  /* Removes bullet points */
  margin: 0;  /* Removes margin */
  display: flex;  /* Flexbox to make the items horizontal */
  justify-content: center;  /* Centers items horizontally */
  align-items: center;  /* Align items vertically */
  box-sizing: border-box;
  background-color: none;
  position: relative;
  overflow-y: hidden;
}

.navbar button {
  margin-inline: 60px;
  font-size: 1em;
  font-weight: bold;
  filter: invert(100%);
  font-family: 'Poppins, sans-serif';
  background: none;  /* Removes background */
  border: none;  /* Removes border */
  cursor: pointer;  /* Changes cursor to pointer to indicate clickable */
  color: var(--link-color);  /* Inherits color from parent for consistency */
  overflow-y: hidden;
}
.dark-mode .navbar button {
  filter: invert(0%);
  background: none !important;
  border: none !important;
  overflow-y: hidden;
}


.navbar button ion-icon {
  font-size: 1.5em;  /* Adjust the size as needed */
  vertical-align: middle;  /* Keeps icon aligned with adjacent text */
  margin-left: 0px;
  font-weight: bold;
  overflow-y: hidden;
}


nav a {
  text-decoration: none;  /* Removes underline from links */
  color: var(--link-color);  /* Sets text color for links */
  font-size: 1em;
  filter: invert(100%); 
  margin-inline: 60px;
  overflow-y: hidden;
}
.dark-mode nav a {
  filter: invert(0%); 
}


footer {
  background-color: var(--footer-background-color);
  width: 100%;
  z-index: 5;
  height: 75px;
  overflow-y: hidden;
}

footer .footer-content {
  background-color: var(--footer-background-color);
  list-style-type: none;  /* Removes bullet points */ /* Removes margin */
  margin-top: 13px;/* Removes default margin */
  margin-bottom: 0;
  display: flex;  /* Flexbox to make the items horizontal */
  justify-content: center;  /* Centers items horizontally */
  align-items: center;  /* Align items vertically */
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  flex-wrap: wrap;
  overflow-y: hidden;
}

footer .footer-content button {
  background: none;
  border: none;  /* Removes border */
  cursor: pointer;  /* Changes cursor to pointer to indicate clickable */
  color: var(--link-color);  /* Inherits color from parent for consistency */
  margin-inline: 75px;
  font-size: 1.5em;
  filter: invert(100%); 
  overflow-y: hidden;
}
.dark-mode footer .footer-content button {
  filter: invert(0%); 
}


footer .footer-content a {
  color: var(--link-color); /* Light green, or choose your own color */
  margin-inline: 75px;
  font-size: 1.5em;
  filter: invert(100%); 
  overflow-y: hidden;
}
.dark-mode footer .footer-content a {
  color: var(--link-color); /* Light green, or choose your own color */
  font-size: 1.5em;
  filter: invert(0%); 
}
.about-text h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-y: hidden;
}
.about-container {
  display: flex;
  align-items: center; /* Vertically center align the items in the container */
  justify-content: center; /* Horizontally center the content */
  gap: 20px; /* Adds space between the image and the text */
  flex-direction: row; /* Default to row for larger screens */
  overflow-y: hidden;
  overflow-x: hidden;
}

.profile-image img {
  width: 400px; /* Adjust the width as needed */
  height: auto; /* Maintains the aspect ratio of the image */
  border-radius: 30px; 
  margin-inline: 0;
  margin: 0;
  border: 4px solid black;
  position: relative;
  z-index: 100;
  overflow-y: hidden;
}
.dark-mode .profile-image img {
  border: 3px solid white;
}
.profile-image img:hover {
  border: 3px solid rgb(231, 163, 5);
}
.about-text {
  max-width: 600px; /* Limits the width of the text content */
  position: relative;
  z-index: 10;
  overflow-y: hidden;
  overflow-x:hidden;
}

.TechStack {
  display: flex;
  justify-content: center;
  gap: 0px;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  overflow-y: hidden;

}
.TechStack .techHalf {
  gap: 0;
  padding:0;
  margin:0;
  flex-wrap: wrap;
  flex-direction: column;
}

.TechStack ion-icon {
  font-size: 75px; /* Larger icons */
  margin-right: 40px; /* Space between icons */
}

.TechStack img {
  width: 75px;
  height: 75px; 
  margin-right: 40px;
  overflow-y: hidden;
}

.dark-mode .TechStack img {
  filter: invert(100%); 
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  * {
    margin-left:auto;
    margin-right:auto;
  }
  .profile-image img {
    margin-top: 10vh;
  }
  .about-container {
    margin-top: 700px;
    margin-bottom: 750px;
    transform: scale(2.3);
    max-width: 100%;
    flex-direction: column; /* Stack items on top of each other for smaller screens */
    align-items: center; /* Center align items when stacked */
    position: relative;
    overflow-y: hidden;
  }
  .logo{
    position: relative;
    height: 60px;
    flex-wrap: wrap;
    margin:0;
    overflow-y: hidden;
  }
  nav {
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  .navbar button {
    position: relative;
    left: -30px;
    font-size: 50px;
    flex-wrap: wrap;
    margin:0;
    margin-inline: 40px;
    overflow-y: hidden;
  }
  
  nav a {
    position: relative;
    font-size: 50px;
    vertical-align: middle;
    margin:0;
    margin-inline: 50px;
    z-index: 10000;
    overflow-y: hidden;
  }
  header {
    vertical-align: middle;  
    margin:0;
    margin-bottom: 25px;
    overflow-y: hidden;
  }
  .footer-content,
  .dark-mode .footer-content{
    max-width: 100%;
    margin-top: 4vh;
    display: flex;
    justify-content: space-around; /* Adjust spacing between items */
    align-items: center; /* Vertically center items */
    overflow: hidden;
  }
  footer,
  .dark-mode footer {
    margin: 0;
    height: 220px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .footer-content p button,
  .dark-mode .footer-content p button {
    transform: scale(3);
    margin: 0;
    position: relative; /* Position relative to its normal position */
    top: -15px; /* Adjust the vertical position */
    left: 10px; /* Adjust the horizontal position */
    overflow-y: hidden;
  }
  .dark-mode .footer-content p a,
  .footer-content p a{
    font-size: 75px;
    position: relative;
    top:5px;
    left: -90px;
    margin-inline: 80px;
    overflow-y: hidden;
  }

  .intro-container {
    margin-top: 10vh;
    margin-bottom: 20vh;
    transform: scale(3);
    max-width: 100%;
    position: relative;
    overflow-y: hidden;
  }
  /* Right here for timeline spacing!! */
  .timeline {
    transform: scale(1.7);
    margin-top:108vh;
    margin-bottom: 118vh;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .timeline-container.right {
    position: relative;
    margin-top: 15vh !important;
    overflow-y: hidden;
  }
  .TechStack {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    transform: scale(2);
    margin-bottom: 70px;
    position: relative;
    overflow-y: hidden;
  }
  .TechStack .firstHalf {
    gap: 0;
    margin: 0;
    padding: 0;
    flex-direction:column;
    flex-wrap: wrap;
  }
  .TechStack ion-icon,
  .TechStack img {
    margin-right: 0; /* Remove right margin for vertical stacking */
    margin-bottom: 10px; /* Add bottom margin for spacing between items */
    margin-top: 10px;
    margin-inline: 30px;
    overflow-y: hidden;
  }
  .test2 h1 {
    position: relative;
    align-items: center;
    transform: scale(2.8);
    overflow-y: hidden;
    margin-bottom: 15vh;
    margin-top: 10vh;
  }
  .exp {
    position: relative;
    transform: scale(1.35);
    top: -200px;
    left: 85px;
  }
  .about-text h2 {
    transform: scale(1.4);
    margin-bottom: 15px;
    overflow-y: hidden;
  }
  .timeline-container {
    overflow-y: hidden;
  }
  .projectWrapper {
    display: flexbox;
    margin: auto;
    align-self: center;
    transform: scale(2.3);
    left: -4vh;
    margin-top: 265vh;
    position: relative;
    overflow-x: visible;
    padding-bottom: 132vh;
    gap: 100px !important;
  }
  .content2 .text {
    order: 1; /* Text appears first in the stacking order */
  }
  .content2 a {
    order: 2; /* Image link appears second in the stacking order */
  }
}

.resume-tech-container img {
  border-radius: 30px;
  margin-right: 10px;
  overflow-y: hidden;
}

.test h2 {
  display: flex;
  align-items: center; /* This will vertically center align the items in the container */
  justify-content: center; /* Horizontally center the content */
  gap: 20px; /* Adds space between the image and the text */
}

.fisherman {
  width: 200px;
  height: auto;
  left: 50%;
  top: -35px;
  transform: translateX(-200px);
  position: absolute;
  z-index: 1;
}

.line {
  content: '';
  position: absolute;
  width: 2px; /* Make the line very thin */
  background-color: #000000;
  top: -20px;
  left: 50%;
  animation: growShrinkLine 15s infinite;
  z-index: 1;
}

.fish {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  animation: reelFish 15s infinite, shake 15s infinite;
}

@keyframes growShrinkLine {
  0% {
    height: 0%;
  }
  50% {
    height: 99.5%;
  }
  100% {
    height: 0%;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
    transform-origin: 0% 50%;
  }
  49% {
    transform: rotate(0deg);
    transform-origin: 0% 50%;
  }
  50% {
    transform: rotate(90deg);
    transform-origin: 0% 50%;
  }
  60% {
    transform: rotate(80deg);
    transform-origin: 0% 50%;
  }
  65% {
    transform: rotate(100deg);
    transform-origin: 0% 50%;
  }
  70% {
    transform: rotate(80deg);
    transform-origin: 0% 50%;
  }
  80% {
    transform: rotate(100deg);
    transform-origin: 0% 50%;
  }
  90% {
    transform: rotate(80deg);
    transform-origin: 0% 50%;
  }
  100% {
    transform: rotate(90deg);
    transform-origin: 0% 50%;
  }
}

@keyframes reelFish {
  0% {
    bottom: 0%;
    left: 90%;
  }
  50% {
    bottom: 0%;
    left: 50%;
  }
  100% {
    bottom: 99.5%;
    left: 50%;
  }
}
.exp h2{
  transform: scale(1.4);
  white-space: wrap;
  position: absolute;
  top:10%;
  left: 60%;
  z-index: 1;
  color: #000000;
  margin-bottom: 150px;
}
.dark-mode .exp h2{
  color: #fff;
}


.dark-mode .line {
  background-color: #ffffff; /* Keeps the timeline line color white in dark mode */
}
.dark-mode .fish {
  filter:invert(100%);
}
.timeline-container {
  margin-top: 50px;
  margin: 10px 40px;
  position: relative;
  background-color: #333;
  width: 50%;
  border-radius: 30px;
  overflow-y: hidden;
}
/* Container for the timeline */
.timeline {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

/* Wrapper to position containers correctly */
.timeline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

/* Left container */
.timeline-container.left {
  margin-top: -150px;
  max-width: 400px;
  max-height: fit-content;
  align-self: flex-end; /* Align to the right of the center line */
  margin-right: calc(50% + 20px); /* 10px + 2px (width of the middle line) */
}
.resme{
  margin: 150px;
  overflow-y: hidden;
}
/* Right container */
.timeline-container.right {
  margin-top: 0vh;
  max-width: 400px;
  max-height: fit-content;
  align-self: flex-start; /* Align to the left of the center line */
  margin-left: calc(50% + 20px);
}
.timeline-container.left h3{
  color: #fff;
}
.timeline-container.left h2{
  color: #fff;
  -webkit-text-stroke: 1px white;
}
.timeline-container.right h3{
  color: #fff;
}
.timeline-container.right h2{
  color: #fff;
  -webkit-text-stroke: 1px white;
}
.timeline-container.left p {
  text-align: left;
  color: #fff;
}


.timeline-container.right p {
  text-align: left;
  color: #fff;
}

.content {
  margin: 20px 30px;
  position: relative;
  border-radius: 6px;
  --text-color: #fff;
  overflow-y: hidden;
}

.TechStack {
  text-align: center;
  margin-top: 20px;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}
.TechStack:hover {
  filter: drop-shadow(20px 20px 20px white);
}


.dark-mode .test2 img {
  filter:invert(100%);
}
.projectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:20px;
}

.content2 {
  margin-left: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  z-index: 5;
  gap: 80px;
}
@media (max-width: 768px) {
  .content2 {
    flex-direction: column;
  }
  
}

.pparse {
  margin-top: 20px;
  width: 540px;
  border-radius: 30px; 
  border: 2px solid black;
  transition: transform 0.3s ease-in-out;
}

.dark-mode .pparse {
  border: 2px solid white;
}

.pparse:hover {
  border: 2px solid rgb(231, 163, 5);
  transform: scale(1.2);
}

.text {
  width: 45%;
  text-align: center;
  margin-top: 20px;
  overflow: hidden !important;
}
.text p {
  margin-top: 20px;
  overflow: visible !important;
}
.project-info {
  color: var(--text-color);
}



@media screen and (min-width: 768px) and (max-width: 991px) {
  .App {
    margin:0;
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .App {
    width: 100%;
    margin:0;
  }
  .projectWrapper {
    flex-direction: column; /* Stack items on top of each other for smaller screens */
    align-items: center; /* Center align items when stacked */
  }
}

@media screen and (min-width: 320px) and (max-width: 480px ) {

  .App {
    margin:0;
    width: 100%;
  }
  
}

body {
  width: 780px !important;
}
